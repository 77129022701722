import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { NotFoundComponent } from './shared/error/notfound/notfound.component';
import { ForbiddenComponent } from './shared/error/forbidden/forbidden.component';

import { AuthGuard, redirectTo } from './auth/_guards/auth.guard';
import { CustomPreloadingStrategyService, RouteStrategyType } from './shared/_services/custom-preloading-strategy.service';
import { MaintenanceComponent } from './shared/error/maintenance/maintenance.component';



export interface StrategyRoute extends Route {
  children?: StrategyRoutes;
  data?: {
    [index: string]: string | string[];
    routeSectionType?: RouteStrategyType;
  };
}

export declare type StrategyRoutes = StrategyRoute[];

const routes: StrategyRoutes = [
  {path: '', redirectTo: redirectTo(), pathMatch: 'full'},
  // Auth
  {path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule), data: {routeSectionType: 'preload'}},
  // Supply
  {
    path: 'supply', loadChildren: () => import('./layout/layout.module').then(m => m.InternalLayoutModule), canActivate: [AuthGuard],
    data: {routeSectionType: 'internal_preload'}
  },
  // One
  {
    matcher: (url) => {
      const allowed = ['portal', 'spearad', 'account'];
      if (allowed.includes(url[0].path)) {
        return {
          consumed: url.slice(0, 1),
        };
      }
      return null;
    },
    loadChildren: () => import('./layout/layout.module').then(m => m.ExternalLayoutModule),
    canActivate: [AuthGuard],
    data: {routeSectionType: 'external_preload'}
  },
  // Partner
  {
    path: 'partner', loadChildren: () => import('./layout/layout.module').then(m => m.PartnerLayoutModule), canActivate: [AuthGuard],
    data: {routeSectionType: 'external_preload'}
  },
  // Auth routes
  {
    path: 'signup',
    loadChildren: () => import('./features/signup/signup.module').then(m => m.SignupModule),
    data: {title: 'Signup', mode: 'self-serve', routeSectionType: 'lazy'}
  },
  {
    path: 'pubsignup',
    loadChildren: () => import('./features/signup/signup.module').then(m => m.SignupModule),
    data: {title: 'Signup', mode: 'managed', routeSectionType: 'lazy'}
  },
  {
    path: 'googlesignup',
    loadChildren: () => import('./features/signup/signup.module').then(m => m.SignupModule),
    data: {title: 'Signup', mode: 'google', routeSectionType: 'lazy'}
  },
  // Errors
  {path: 'error/403', component: ForbiddenComponent, data: {title: 'Forbidden'}},
  {path: 'maintenance', component: MaintenanceComponent},

  // Path for 404, all others routes
  {path: '**', component: NotFoundComponent, data: {title: 'Not found'}}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', preloadingStrategy: CustomPreloadingStrategyService})],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
