import { AppFilter, AppTable } from '../../../shared/_models/models';

export const audienceTargetingListConfig: AppTable = new AppTable('audienceTargetingTable', 1, 20, 'name',
  'desc', true, true);

// Common filters shared across all configs
const commonSearchFilter = (placeholder: string) => new AppFilter(
  'segmentsRxModelSearchFilter',
  'SEARCH',
  '',
  null,
  placeholder,
  'default',
  true,
  true,
  {nameProp: 'keyword'},
  undefined, undefined, undefined, undefined, undefined, undefined,
  undefined, undefined, undefined, undefined, undefined, undefined,
  undefined, undefined, undefined,
  'query',
  undefined, undefined, undefined, undefined,
  true
);

const commonSortByFilter = (sortOptions: any[], defaultSort: any) => new AppFilter(
  'segmentsRxModelSortBy',
  'SELECT_WITH_SORT',
  '',
  null,
  'Sort By',
  'default',
  false,
  true,
  {nameProp: 'sort'},
  sortOptions,
  undefined, undefined, undefined, undefined, undefined,
  defaultSort,
  undefined, undefined, undefined, undefined, undefined, undefined,
  undefined, undefined,
  'query',
  undefined, undefined, undefined, undefined,
  true
);

const commonTypeFilter = (typeOptions: any[], defaultOptions: any[] = undefined) => new AppFilter(
  'segmentsRxModelFilterType',
  'SELECT',
  '',
  null,
  'Filter by Type',
  'multiple',
  true,
  true,
  {nameProp: 'provider'},
  typeOptions,
  undefined, undefined, undefined, undefined, undefined,
  defaultOptions,
  undefined, undefined, undefined, undefined, undefined,
  undefined, undefined, undefined,
  'query',
  undefined, undefined, undefined, undefined,
  true
);

const commonProviderFilter = (isDisabled: boolean = true) => new AppFilter(
  'audienceTargetingModelProvider',
  'SELECT',
  'provider',
  null,
  'Filter by Provider',
  'multiple',
  true,
  true,
  {nameProp: 'provider'},
  [],
  undefined, undefined, undefined, undefined, undefined, undefined,
  undefined,
  isDisabled,
  undefined, undefined, undefined, undefined, undefined, undefined,
  'query',
  undefined, undefined, undefined, undefined,
  true
);

export const audienceTargetingModelFiltersConfig: AppFilter[] = [
  new AppFilter('audienceTargetingModelSearchFilter', 'SEARCH', '', null, 'Search by Keyword or ID',
    'default', true, true, {nameProp: 'name'}, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, 'query',
    undefined, undefined, undefined, undefined, true),
  new AppFilter('audienceTargetingModel3rdPartyFilter', 'SELECT', 'publisherId', null, 'Filter by Publisher',
    'default', false, true, {nameProp: 'publisherId'},
    [
      {id: '3rdParty', value: '3rdParty', displayName: '3rd Party'},
      {id: '1stParty', value: '1stParty', displayName: '1st Party'}
    ],
    [{id: '3rdParty', value: '3rdParty', displayName: '3rd Party'}], undefined, undefined, undefined, undefined,
    [{id: '3rdParty', value: '3rdParty', displayName: '3rd Party'}],
    undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, 'query',
    undefined, undefined, undefined, undefined, true),
  new AppFilter('audienceTargetingModelSortBy', 'SELECT_WITH_SORT', '', null, 'Sort By',
    'default', false, true, {nameProp: 'sort'}, [], undefined,
    undefined, undefined, undefined, undefined, [{id: 'name', value: 'name', displayName: 'Sort by Name'}],
    undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, 'query',
    undefined, undefined, undefined, undefined, true),
  commonProviderFilter()
];

// Rx specific sort options
const rxSortOptions = [
  {id: 'name', value: ['displayName', 'segmentAudience.name'], displayName: 'Sort by Name'},
  {id: 'cpm', value: ['cpm'], displayName: 'Sort by Price'},
  {id: 'dmpAudienceId', value: ['segmentAudience.dmpAudienceId'], displayName: 'Sort by DMP ID'},
  {id: 'type', value: ['type'], displayName: 'Sort by Type'},
  {id: 'providerId', value: ['providerName'], displayName: 'Sort by Provider'}
];

const rxDefaultSort = [{id: 'name', value: ['displayName', 'segmentAudience.name'], displayName: 'Sort by Name'}];

const rxTypeOptions = [
  {id: 'audience', value: 'audience', displayName: 'Audience' },
  {id: 'comscore', value: 'comscore', displayName: 'Comscore' },
  {id: 'contentgenre', value: 'contentgenre', displayName: 'Content Genre' },
  {id: 'contentlanguage', value: 'contentlanguage', displayName: 'Content Language' },
  {id: 'contentrating', value: 'contentrating', displayName: 'Content Rating' },
];

const rxPartnerDefaultTypeOptions = [{id: 'audience', value: 'audience', displayName: 'Audience' }];

export const segmentsRxModelFiltersConfig: AppFilter[] = [
  commonSearchFilter('Search by Keyword or DMP ID'),
  commonSortByFilter(rxSortOptions, rxDefaultSort),
  commonTypeFilter(rxTypeOptions),
  commonProviderFilter()
];

// Partner specific sort options
const partnerSortOptions = [
  {id: 'name', value: ['displayName', 'segmentAudience.name'], displayName: 'Sort by Name'},
  {id: 'type', value: ['type'], displayName: 'Sort by Type'},
  {id: 'providerId', value: ['providerName'], displayName: 'Sort by Provider'}
];

const partnerDefaultSort = [{id: 'name', value: ['displayName', 'segmentAudience.name'], displayName: 'Sort by Name'}];

const partnerTypeOptions = [
  {id: 'audience', value: 'audience', displayName: 'Audience' },
  {id: 'contentgenre', value: 'contentgenre', displayName: 'Content Genre' },
  {id: 'contentlanguage', value: 'contentlanguage', displayName: 'Content Language' },
  {id: 'contentrating', value: 'contentrating', displayName: 'Content Rating' },
];

export const segmentsPartnerModelFiltersConfig: AppFilter[] = [
  commonSearchFilter('Search by Keyword'),
  commonSortByFilter(partnerSortOptions, partnerDefaultSort),
  commonTypeFilter(partnerTypeOptions, rxPartnerDefaultTypeOptions),
  commonProviderFilter(false)
];

// eslint-disable-next-line max-len
export const SEGMENT_TITLE_BASIC_RX = 'Here you can create inclusion groups and/or an exclusion group, and assign different segment types to them';
// eslint-disable-next-line max-len
export const SEGMENT_TITLE_ADDITIONAL_RX =  ': Content (genre, language, rating), Data (audience, Comscore), and/or Media (App Bundle/Site Domain, App ID/Site ID)';
export const SEGMENT_MODAL_TITLE_RX = 'Add Content and Data Segments';
export const SEGMENT_MODAL_TITLE_SSP = 'Add Audience Segments';
export const SEGMENT_MODAL_SUBTITLE_RX = 'Select one or more available content or data segments.';
export const SEGMENT_MODAL_SUBTITLE_SSP = 'Select one or more available audience segments.';
export const segmentsMock = [
  {
    segmentId: 1,
    dmpAudienceId: 1,
    segmentName: 'seg1',
    description: 'desc1',
    providerName: 'prov1',
    cpm: 1.11,
    segmentType: 'type1',
  },
  {
    segmentId: 2,
    dmpAudienceId: 2,
    segmentName: 'seg2',
    description: 'desc2',
    providerName: 'prov2',
    cpm: 2.22,
    segmentType: 'type2',
  },
  {
    segmentId: 3,
    dmpAudienceId: 3,
    segmentName: 'seg3',
    description: 'desc3',
    providerName: 'prov3',
    cpm: 3.33,
    segmentType: 'type3',
  },
  {
    segmentId: 4,
    dmpAudienceId: 4,
    segmentName: 'seg4',
    description: 'desc4',
    providerName: 'prov4',
    cpm: 4.44,
    segmentType: 'type4',
  },
  {
    segmentId: 5,
    dmpAudienceId: 5,
    segmentName: 'seg5',
    description: 'desc5',
    providerName: 'prov5',
    cpm: 5.55,
    segmentType: 'type5',
  },
];

