<div class="segment-wrapper" [ngClass]="{
      'expanded': isExpanded,
      'select-mode': mode === 'select',
      'view-mode': mode === 'view',
      'selected': status === 'selected',
      'disabled': status === 'disabled'
    }"
     (click)="onSegmentClick()">

  <div class="segment cursor-pointer">
    <div class="seg-name-id overflow-y-hidden" [ngClass]="{'padding-bottom-1': isExpanded}">
      <div class="text-content">
        <span *ngIf="isInternalUser" class="seg-id margin-right-small">[{{segment.dmpAudienceId || segment.segmentId || 'NEW'}}]</span>
        <span class="seg-name" [appEllipsisAndTooltip]="mode==='view'" [nz-tooltip]="(segment.type?.includes('media') || segment.type?.includes('pubid')) ? segment?.description : segment.segmentName">
          {{(segment.type?.includes('media') || segment.type?.includes('pubid')) ? segment?.description : segment.segmentName}}
        </span>
        <nz-tag *ngIf="segment.segmentAudienceDisabled || segment.status === 'inactive'" class="inactive-status-ligher margin-right-small bold-font">
          <span>
            Inactive
          </span>
        </nz-tag>
      </div>
      <div class="seg-name-expand-btn cursor-pointer" appShowIfTextLeaked
           [nz-tooltip]="isExpanded ? 'Shrink' : 'Expand'"
           (click)="$event.stopPropagation(); toggleExpansion()">
        <fa-icon icon="chevron-down"
                 [rotate]="isExpanded ? 180 : null"
                 class="font-icon-color-darker background-white"></fa-icon>
      </div>
    </div>
    <div class="seg-divider" *ngIf="mode === 'select'"></div>
    <div class="seg-provider overflow-y-hidden"
         [ngClass]="'multilineEllipsisText-' + (status === 'disabled' ? '1' : '2')"
         [ngClass]="{'display-flex': rxDealMode && segment.type !== 'audience', 'justify-content-center': rxDealMode && segment.type !== 'audience'}">
      <ng-container *ngIf="isInternalUser || (!isInternalUser && segment.type === 'mediadomain')">
        <div *ngIf="segment.type === 'mediadomain' || segment.type === 'mediaid' || segment.type === 'pubid'"
             class="margin-right-1">
          <a (click)="editMediaDomain(segment)">edit</a>
        </div>
      </ng-container>
      <ng-container *ngIf="rxDealMode">
        <div class="display-flex align-items-center">
          <span>{{ (segment.segmentTypeName || segmentType[segment.type]) }}</span>
          <span class="margin-left-1">{{ segment.providerName || segment.providerId || '' }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="!rxDealMode">
        {{ segment.publisherId ? publisherName : segment.providerName }}
      </ng-container>
    </div>
    <div *ngIf="!rxDealMode || (rxDealMode && segment.type === 'audience')" class="seg-cpm">{{segment.cpm | currency}}</div>
    <div *ngIf="mode === 'view'" class="seg-delete display-flex align-items-center" nz-popconfirm nzOkText="Delete"
         [nzPopconfirmTitle]="deleteSegmentPopupConfirmTitleTpl" (nzOnConfirm)="deleteSegment.emit()">
      <fa-icon icon="times" class="fa-md cursor-pointer"></fa-icon>
    </div>
    <div class="seg-targeting-type justify-self-center align-self-end font-icon-color-mid-dark small-font text-center"
         *ngIf="mode === 'select'">
      <div class="seg-already-added" *ngIf="status === 'disabled'">Already added</div>
    </div>
  </div>

</div>
<ng-template #deleteSegmentPopupConfirmTitleTpl>
  <fa-icon icon="exclamation-circle"
           class="font-icon-color-warning-mid-dark margin-right-small"></fa-icon>
  Are you sure you want to delete this segment?
</ng-template>
