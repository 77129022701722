import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import {
  AppFilter,
  AudienceTargetingGroup,
  AudienceTargetingSegment,
  CommonDataModel,
  DomainBundlePayload,
  UpdateAppFiltersRenderer,
  JapiQuery,
  JapiTableFilter,
  PortalType,
  RxSegmentMapPayload
} from '../../_models/models';
import { AudienceTargetingService } from '../../../features/inventory-packages/_services/audience-targeting.service';
import { SharedService } from '../../_services/shared.service';
import { cloneDeep, find, isEmpty, pullAt, reject, some } from 'lodash';

import { Store } from '@ngrx/store';
import * as fromAudienceTargeting from '../../../features/inventory-packages/_store/audience-targeting.reducer';
import * as AudienceTargetingActions from '../../../features/inventory-packages/_store/audience-targeting.actions';
import {
  audienceTargetingModelFiltersConfig,
  segmentsRxModelFiltersConfig,
  audienceTargetingListConfig,
  segmentsPartnerModelFiltersConfig,
  SEGMENT_TITLE_BASIC_RX,
  SEGMENT_TITLE_ADDITIONAL_RX,
  SEGMENT_MODAL_TITLE_SSP,
  SEGMENT_MODAL_TITLE_RX,
  SEGMENT_MODAL_SUBTITLE_RX,
  SEGMENT_MODAL_SUBTITLE_SSP
} from '../../../features/inventory-packages/_services/audience-targeting.config';
import { FormHelper } from 'src/app/_common';
import { rxSegmentAddExcludeButton, rxSegmentAddIncludeButton } from '../../../features/exchange-deals/_services/exchange-deals.config';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-audience-targeting',
  templateUrl: './audience-targeting.component.html',
  styleUrls: ['./audience-targeting.component.less'],
  animations: [
    trigger('collapseSegments', [
      transition(':enter', [
        animate('0.3s', keyframes([
          style({height: '0px', opacity: 0, offset: 0}),
          style({height: '*', opacity: 0, offset: 0.5}),
          style({height: '*', opacity: 1, offset: 1}),
        ])),
      ]),
      transition(':leave', [
        animate('0.3s', keyframes([
          style({height: '*', opacity: 1, offset: 0}),
          style({height: '*', opacity: 0, offset: 0.5}),
          style({height: '0px', opacity: 0, offset: 1}),
        ])),
      ]),
    ]),
  ],
})
export class AudienceTargetingComponent implements OnInit, OnDestroy {
  @Output() groupsChanged: EventEmitter<AudienceTargetingGroup[]> = new EventEmitter();
  @Output() segmentMapsChanged: EventEmitter<Map<number, RxSegmentMapPayload>> = new EventEmitter();
  @Output() filterChanged: EventEmitter<{ filters: AppFilter[]; filterId: string; isResetFilters: boolean }> = new EventEmitter();
  @Output() pageChanged: EventEmitter<number> = new EventEmitter();
  @Output() csvFileChanged: EventEmitter<NzUploadFile[]> = new EventEmitter();
  @Output() initGroupsChange: EventEmitter<boolean> = new EventEmitter();
  @Input() publisherId: number;
  @Input() publisherName: string;
  @Input() initGroups = false;
  @Input() rxDealMode = false;
  @Input() rxSegmentsMetaData: CommonDataModel<AudienceTargetingSegment[]>;
  @Input() segmentList: AudienceTargetingSegment[] = [];

  mediaMode = false;
  rxSegmentAddIncludeButton = rxSegmentAddIncludeButton;
  rxSegmentAddExcludeButton = rxSegmentAddExcludeButton;
  audienceTargetingTableConf = {...audienceTargetingListConfig};
  tempGroup: AudienceTargetingGroup = null;
  portalType: PortalType;
  form: UntypedFormArray = this.fb.array([]);
  EMPTY_GROUP_CONTROLS = this.fb.group({
    id: null,
    name: ['', [Validators.required]],
    include: true,
    segments: [[], [Validators.required]],
    isOpen: true,
  });
  isModalVisible = false;
  groupIdxForModal: number = null;
  segmentIdx = 0;
  isSegmentsLoading$: Observable<boolean>;
  modalFilters$: Observable<AppFilter[]>;
  segmentsToAdd: AudienceTargetingSegment[] = [];
  editMedia: boolean;
  segmentMapsPayload: Map<number, RxSegmentMapPayload> = new Map();
  tempSegmentMap: AudienceTargetingSegment = null;
  domainBundlePayload: DomainBundlePayload;
  domainBundleLength = 0;
  uploadedFile: NzUploadFile[] = [];
  totalElements$: Observable<number>;
  firstPartyMode: boolean;
  updateAudienceTargetingFiltersToDisplay: UpdateAppFiltersRenderer[];
  segmentTitleRx: string;
  modalSegmentTitle: string;
  modalSegmentSubtitle: string;
  private sspQueryParam: JapiQuery;

  constructor(
    private fb: UntypedFormBuilder,
    private fh: FormHelper,
    private audienceTargetingService: AudienceTargetingService,
    private store: Store<fromAudienceTargeting.AudienceTargetingState>,
    private sharedService: SharedService,
    private activatedRoute: ActivatedRoute,
  ) {
  }
  get groups() {
    return this.form ? this.form.value : [];
  }
  @Input() set groups(newGroups: AudienceTargetingGroup[]) {
    if (this.initGroups) {
      this.segmentMapsPayload = new Map();
      this.form = this.fb.array([]);
      this.initGroups = false;
      this.initGroupsChange.emit(false);
    }
    if (newGroups && newGroups.length && this.form.value.length === 0) {
      this.pushGroupsToForm(newGroups);
    }
  }

  @Input() set dealPartnerId(value: number) {
    if (this.rxDealMode && this.portalType === 'supply') {
      this.updateAudienceTargetingFiltersToDisplay = [
        {filterId: 'segmentsRxModelFilterType', selectOptionId: 'comscore', hideCondition: !!value},
      ];
    }
  }

  ngOnInit(): void {
    this.initRouteParams();
    this.initFormTitles();
    this.initAppFilters();
    this.initRequests();
  }

  onGroupNameChange(groupIdx: number, value: string): void {
    this.updateControl(groupIdx, 'name', value);
    this.checkGroupValidity(groupIdx);
  }

  getNewGroupForm(valuesToPatch?: AudienceTargetingGroup): UntypedFormGroup {
    const newGroupForm = cloneDeep(this.EMPTY_GROUP_CONTROLS);
    newGroupForm.patchValue(valuesToPatch || {});
    return newGroupForm;
  }

  onDomainBundleFileUpdated(uploadedFile: NzUploadFile[]) {
    this.uploadedFile = uploadedFile;
    this.csvFileChanged.emit(this.uploadedFile);
  }

  onDomainBundleFileRemoved() {
    this.uploadedFile = null;
  }

  addGroup(type: boolean): number {
    this.tempGroup = {
      id: null,
      name: `${type ? 'Inclusion' : 'Exclusion'} Group`,
      include: type,
      segments: [],
      isOpen: true,
    };
    let newGroupIdx = this.groups.length;
    // making sure that exclude group is always last
    if (this.groups.length && this.groups[this.groups.length - 1].include === false && this.tempGroup.include === true) {
      newGroupIdx--;
    }
    this.tempGroup.name += type ? ` ${newGroupIdx + 1}` : '';
    this.renameDuplicateGroupName();
    return newGroupIdx;
  }

  deleteGroup(idx: number): void {
    if (this.rxDealMode) {
      const dealSegmentGroup: AudienceTargetingGroup = this.form.controls[idx].value;
      dealSegmentGroup.segments.forEach(segment => {
        this.segmentMapsPayload.delete(segment.segmentId);
      });
    }
    this.form.removeAt(idx);
    this.emitGroupsChanges();
  }

  deleteSegment(groupIdx: number, segmentIdx: number): void {
    const segmentsControl = this.form.get(`${groupIdx}.segments`);
    const segmentsCloneToModify = cloneDeep(segmentsControl.value);
    pullAt(segmentsCloneToModify, [segmentIdx]);
    segmentsControl.setValue(segmentsCloneToModify);
    this.checkGroupValidity(groupIdx);
    this.emitGroupsChanges();
  }

  toggleSegmentSelected(segment: AudienceTargetingSegment): void {
    const propName = this.rxDealMode ? 'segmentId' : 'dmpAudienceId';
    if (find(this.segmentsToAdd, [propName, segment[propName]])) { // If the clicked segment is already selected
      this.segmentsToAdd = reject(this.segmentsToAdd, [propName, segment[propName]]);
    } else { // If the clicked segment is not selected
      this.segmentsToAdd.push(cloneDeep(segment));
    }
  }

  getSegmentStatus(segment: AudienceTargetingSegment): 'selected' | 'disabled' {
    const propName = this.rxDealMode ? 'segmentId' : 'dmpAudienceId';
    if (find(this.segmentsToAdd, [propName, segment[propName]])) {
      return 'selected';
    }
    let alreadySelected = false;
    this.groups.forEach(group => {
      if (find(group.segments, [propName, segment[propName]])) {
        alreadySelected = true;
      }
    });
    if (alreadySelected) {
      return 'disabled';
    }
    return null;
  }

  segmentNameChange(segmentDescription: string): void {
    if (this.editMedia) {
      if (this.segmentMapsPayload.has(this.tempSegmentMap?.segmentId)) {
        this.segmentMapsPayload.get(this.tempSegmentMap.segmentId).updateSegmentDescription(segmentDescription);
      } else {
        const existDescription = this.tempSegmentMap.description;
        const rxSegmentMapPayload = new RxSegmentMapPayload(existDescription);
        this.segmentMapsPayload.set(
          this.tempSegmentMap.segmentId, rxSegmentMapPayload
        );
      }
    }
    this.tempSegmentMap.description = segmentDescription;
  }

  domainBundlePayloadChange(domainBundlePayload: DomainBundlePayload): void {
    this.domainBundlePayload = domainBundlePayload;
    this.domainBundleLength = domainBundlePayload?.addedDomainBundles?.length + domainBundlePayload?.removedDomainBundles?.length;
  }

  domainBundlePayloadUpdated(): void {
    if (this.editMedia) {
      if (this.segmentMapsPayload.has(this.tempSegmentMap?.segmentId)) {
        this.segmentMapsPayload.get(this.tempSegmentMap.segmentId).updateDomainBundlePayload(this.domainBundlePayload);
      } else {
        const rxSegmentMapPayload = new RxSegmentMapPayload(this.tempSegmentMap.segmentName);
        rxSegmentMapPayload.updateDomainBundlePayload(this.domainBundlePayload);
        this.segmentMapsPayload.set(
          this.tempSegmentMap.segmentId, rxSegmentMapPayload
        );
      }
    } else {
      if (this.uploadedFile.length > 0) {
        this.tempSegmentMap.segmentMaps = [this.uploadedFile[0].name];
      } else {
        this.tempSegmentMap.segmentMaps = this.domainBundlePayload?.addedDomainBundles.map(d => d.domainBundle);
      }
      this.segmentsToAdd = [this.tempSegmentMap];
    }
  }

  addMediaDomain(segment: AudienceTargetingSegment = null): void {
    if (segment) {
      this.tempSegmentMap = segment;
    } else {
      this.tempSegmentMap = {
        segmentId: undefined,
        segmentName: '',
        type: 'mediadomain',
        segmentMaps: [],
        sourceSegmentId: undefined
      };
    }
  }

  openModalEditSegmentMedia(segment: AudienceTargetingSegment, groupIdx: number, segIdx: number): void {
    this.segmentIdx = segIdx;
    this.groupIdxForModal = groupIdx;
    this.editMedia = Boolean(segment.segmentId);
    this.mediaMode = true;
    this.addMediaDomain(segment);
    this.isModalVisible = true;
  }

  openModal(groupIdx: number, mediaMode = false, existGroup = false): void {
    this.mediaMode = mediaMode;
    if (mediaMode) {
      this.addMediaDomain();
    }
    if (existGroup) {
      this.segmentIdx = this.form.get(`${groupIdx}.segments`).value.length;
    }
    this.groupIdxForModal = groupIdx;
    this.onFiltersChange(null, true);
    this.isModalVisible = true;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    if (!this.modalFilters$ || this.rxDealMode) {
      this.initSubscriptions();
    }
  }

  onCloseModal(closeMethod: string): void {
    this.audienceTargetingService.gtmCloseEvent(closeMethod);
    if (closeMethod === 'cancelButton') {
      if (this.segmentMapsPayload.has(this.tempSegmentMap?.segmentId)) {
        this.tempSegmentMap.segmentName = this.segmentMapsPayload.get(this.tempSegmentMap.segmentId).existSegmentDescription;
      }
    }
    this.isModalVisible = false;
    this.tempSegmentMap = null;
    this.groupIdxForModal = null;
    this.segmentIdx = 0;
    this.segmentsToAdd = [];
    this.mediaMode = false;
    this.tempGroup = null;
    this.editMedia = null;
    this.domainBundlePayload = null;
    this.domainBundleLength = 0;
  }

  onFiltersChange(event: { filters: AppFilter[]; filterId: string }, isResetFilters = false): void {
    if (this.rxDealMode) {
      if (event?.filterId === 'segmentsRxModelFilterType') {
        const typeFilter = event.filters.find(f => f.id === 'segmentsRxModelFilterType');
        const providerFilter = event.filters.find(f => f.id === 'audienceTargetingModelProvider');

        const isAudienceType = typeFilter?.selectedValues?.length === 1 &&
          typeFilter.selectedValues[0].value.toLowerCase() === 'audience';

        if (!isAudienceType) {
          providerFilter.selectedValues = [];
        }
        providerFilter.isDisabled = !isAudienceType;
      }

      if (event?.filters) {
        this.modalFilters$ = of(event.filters);
      }

      this.filterChanged.emit({...event, isResetFilters});
    } else {
      let newFilters: AppFilter[];
      if (isResetFilters) {
        newFilters = this.sharedService.resetFilterSelectedValues(cloneDeep(audienceTargetingModelFiltersConfig));
      } else {
        newFilters = event.filters;
      }
      this.store.dispatch(new AudienceTargetingActions.UpdateModalFilters({modalFilters: newFilters}));
    }
  }

  onAddSegmentsToGroup(): void {
    if (this.mediaMode) {
      this.domainBundlePayloadUpdated();
    }
    if (this.editMedia) {
      this.segmentMapsChanged.emit(this.segmentMapsPayload);
    } else {
      if (this.tempGroup !== null) {
        this.form.insert(this.groupIdxForModal, this.getNewGroupForm(this.tempGroup));
        this.checkGroupValidity(this.groupIdxForModal);
      }
      const segmentsControl = this.form.get(`${this.groupIdxForModal}.segments`) as UntypedFormArray;
      if (this.mediaMode) {
        const seg: AudienceTargetingSegment[] = cloneDeep(segmentsControl.value);
        seg[this.segmentIdx] = this.segmentsToAdd[0];
        segmentsControl.setValue(seg);
      } else {
        const newSegmentsValue = [...cloneDeep(segmentsControl.value), ...this.segmentsToAdd];
        segmentsControl.setValue(newSegmentsValue);
      }
      this.checkGroupValidity(this.groupIdxForModal);
      this.emitGroupsChanges();
    }
    this.toggleGroupCollapse(this.groupIdxForModal, true);
    this.onCloseModal('closeOnSaveButton');
  }

  emitGroupsChanges(): void {
    this.form.updateValueAndValidity();
    const clone = cloneDeep(this.groups);
    clone.forEach(group => {
      delete group.isOpen;
    });
    this.groupsChanged.emit(clone);

  }

  hasExclusionGroup(): boolean {
    return some(this.groups, ['include', false]);
  }

  toggleGroupCollapse(groupIdx: number, toState?): void {
    const isOpen = this.form.get(`${groupIdx}.isOpen`).value;
    if (toState !== undefined && isOpen === toState) {
      return;
    } else {
      this.form.get(`${groupIdx}.isOpen`).setValue(!isOpen);
    }
    this.form.updateValueAndValidity();
  }

  trackByFn(index: number, _item: AudienceTargetingGroup): number {
    return index;
  }

  hasError(groupIdx: number, field: keyof AudienceTargetingGroup): string {
    return this.form.get(`${groupIdx}.${field}`).errors ? 'error' : null;
  }

  checkGroupValidity(groupIdx: number): void {
    this.validateGroupName(groupIdx);
    this.validateGroupSegments(groupIdx);
  }

  validateGroupName(groupIdx: number): void {
    const name = this.form.get(`${groupIdx}.name`).value;
    const errors = {};
    let isUnique = true;
    this.form.controls.forEach((group, i) => {
      if (i !== groupIdx && group.value.name.trim() === name.trim()) {
        isUnique = false;
      }
    });
    if (!isUnique) {
      errors['dupName'] = true;
    }
    if (name.trim().length === 0) {
      errors['required'] = true;
    }
    this.form.get(`${groupIdx}.name`).setErrors(isEmpty(errors) ? null : errors);
    this.form.updateValueAndValidity();
  }

  validateGroupSegments(groupIdx: number): void {
    const segments = this.form.get(`${groupIdx}.segments`).value;
    const errors = {};
    if (segments.length === 0) {
      errors['required'] = true;
    }
    this.form.get(`${groupIdx}.segments`).setErrors(isEmpty(errors) ? null : errors);
    this.form.updateValueAndValidity();
  }

  isFormInvalid(): boolean {
    return this.form.invalid;
  }

  markFormAsDirty(): void {
    this.fh.markFormGroupDirtyAndUpdateValidity(this.form);
    this.form.controls.forEach((groupControl, i) => {
      this.checkGroupValidity(i);
      if (groupControl.invalid) {
        groupControl.get('isOpen').setValue(true);
      }
    });
  }

  indexChanged(pageIndex: number): void {
    if (this.rxDealMode) {
      this.pageChanged.emit(pageIndex);
    } else {
      this.updateSegmentsFromServer(null, pageIndex);
    }
  }

  addMediaOrSegmentGroup(actionId: string): void {
    switch (actionId) {
      case 'addInclusionGroup':
        this.openModal(this.addGroup(true));
        break;
      case 'addInclusionGroupMedia':
        this.openModal(this.addGroup(true), true);
        break;
      case 'addExclusionGroup':
        this.openModal(this.addGroup(false));
        break;
      case 'addExclusionGroupMedia':
        this.openModal(this.addGroup(false), true);
        break;
    }
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this.store.dispatch(new AudienceTargetingActions.ClearFromStoreAudienceTargetingAction());
    }, 0);
  }

  private initRequests(): void {
    this.audienceTargetingService.getAudienceTargetingProviderList();
    if (!this.rxDealMode) {
      this.audienceTargetingService.getAudienceTargetingSortByList();
    }
  }

  private updateSegmentsFromServer(appFilters?: AppFilter[], index = 1): void {
    this.audienceTargetingTableConf.pageIndex = index;
    if (appFilters) {
      this.handleSort(appFilters);
      this.sspQueryParam = this.sharedService.buildTableQuery(appFilters, this.audienceTargetingTableConf);
      this.handleSearchById();
      this.handleFilterActiveParties();
    } else {
      this.sspQueryParam.paging.number = index;
    }
    this.audienceTargetingService.getAudienceTargetingSegments(this.sspQueryParam);
  }

  private handleFilterActiveParties(): void {
    const PUBLISHER_ID = 'publisherId';
    const filters = this.sspQueryParam.filter.filters;
    const publisherIdIndex = filters?.findIndex(filter => filter.fieldName === PUBLISHER_ID);
    if (this.publisherId && publisherIdIndex > -1 && filters[publisherIdIndex]?.value === '1stParty') {
      this.firstPartyMode = true;
      filters[publisherIdIndex].value = this.publisherId;
      return;
    } else if (publisherIdIndex > -1) {
      filters[publisherIdIndex].operation = 'IS_NULL';
      filters[publisherIdIndex].value = null;
    }
    const filterStatusActive: JapiTableFilter = {
      fieldName: 'status',
      operation: 'EQUALS',
      value: 'active'
    };
    filters.push(filterStatusActive);
    this.firstPartyMode = false;
  }

  private handleSearchById(): void {
    if (this.portalType === 'portal') {
      return;
    }
    const filters = this.sspQueryParam.filter.filters;
    const nameFilter = filters?.find(filter => filter.fieldName === 'name');
    const valueAsNumber = Number(nameFilter?.value);
    if (valueAsNumber || valueAsNumber > 0) {
      nameFilter.fieldName = 'dmpAudienceId';
      nameFilter.value = valueAsNumber;
      nameFilter.operation = 'EQUALS';
    }
  }

  private handleSort(appFilters: AppFilter[]): void {
    const findSelectWithSort = appFilters.find(filter => filter.type === 'SELECT_WITH_SORT');
    if (findSelectWithSort?.selectedValues && findSelectWithSort.selectedValues.length > 0) {
      this.audienceTargetingTableConf.sortDirection = findSelectWithSort.sortType;
      this.audienceTargetingTableConf.sortBy = findSelectWithSort.selectedValues[0].value;
    }
  }

  private initFirstPartyFilter(appFilters: AppFilter[]): void {
    const firstPartyFilter = appFilters?.find(filter => filter.id === 'audienceTargetingModel3rdPartyFilter');
    const providerFilter = appFilters?.find(filter => filter.id === 'audienceTargetingModelProvider');
    const isFirstParty = firstPartyFilter.selectedValues?.[0]?.id === '1stParty';
    this.disableFirstPartyFilter(firstPartyFilter);
    this.disableProviderFilter(providerFilter, isFirstParty);
    this.toggleAppFilterRenderer(isFirstParty);
  }

  private disableFirstPartyFilter(firstPartyFilter: AppFilter): void {
    if (this.publisherId && firstPartyFilter) {
      firstPartyFilter.tooltip = null;
      firstPartyFilter.isDisabled = false;
    } else {
      firstPartyFilter.tooltip = 'You must select a Publisher to view first party audience segments';
      firstPartyFilter.isDisabled = true;
    }
  }

  private disableProviderFilter(providerFilter: AppFilter, isFirstParty?: boolean): void {
    if (isFirstParty) {
      providerFilter.selectedValues = [];
      providerFilter.isDisabled = true;
    } else {
      providerFilter.isDisabled = false;
    }
  }

  private toggleAppFilterRenderer(isFirstParty?: boolean): void {
    this.updateAudienceTargetingFiltersToDisplay = [
      {filterId: 'audienceTargetingModelSortBy', selectOptionId: 'dmpAudienceId', hideCondition: (this.portalType === 'portal')},
      {filterId: 'audienceTargetingModelSortBy', selectOptionId: 'cpm', hideCondition: isFirstParty},
      {filterId: 'audienceTargetingModelSortBy', selectOptionId: 'dmpAudienceProvider.providerName', hideCondition: (isFirstParty)}
    ];
  }

  private initAppFilters(): void {
    this.toggleAppFilterRenderer();
    const searchFilter = audienceTargetingModelFiltersConfig.find(appFilter => appFilter.id === 'audienceTargetingModelSearchFilter');
    if (this.portalType === 'portal') {
      searchFilter.placeholder = 'Search by Keyword';
    } else if (this.portalType === 'supply') {
      searchFilter.placeholder = 'Search by Keyword or ID';
    }
  }

  private pushGroupsToForm(rawGroups: AudienceTargetingGroup[] = []): void {
    rawGroups.forEach(group => {
      this.form.push(this.getNewGroupForm(group));
    });
  }

  private initSubscriptions(): void {
    if (this.rxDealMode) {
      let filters: AppFilter[];
      if (this.portalType === 'supply') {
        filters = cloneDeep(segmentsRxModelFiltersConfig);
      } else {
        filters = cloneDeep(segmentsPartnerModelFiltersConfig);
      }

      this.modalFilters$ = of(filters);
    } else {
      this.totalElements$ = this.store.select(fromAudienceTargeting.getTotalElements);
      this.modalFilters$ = this.store.select(fromAudienceTargeting.getModalFilters).pipe(
        map(res => {
          const appFilters = structuredClone(res);
          this.initFirstPartyFilter(appFilters);
          return appFilters;
        }),
        tap(res => {
          if (this.isModalVisible) {
            this.updateSegmentsFromServer(res);
          }
        }),
      );
      this.modalFilters$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
      );
      this.isSegmentsLoading$ = this.store.select(fromAudienceTargeting.getIsLoading);
    }
  }

  private updateControl(groupIdx: number, controlName: string, value): void {
    this.form.get(`${groupIdx}.${controlName}`).patchValue(value);
    this.form.updateValueAndValidity({onlySelf: false, emitEvent: true});
    this.emitGroupsChanges();
  }

  private renameDuplicateGroupName(): void {
    const duplicate = this.form.controls.filter(x => x.value.name.includes(this.tempGroup.name));
    if (duplicate.length > 0) {
      const numberInsideBrackets = new RegExp(/^.*?\([^\d]*(\d+)[^\d]*\).*$/);
      const arrayCopies = duplicate.map(x => {
        const res = x.value.name.match(numberInsideBrackets);
        return res ? Number(res[1]) : 0;
      });
      const copyNumber = Math.max(...arrayCopies) + 1;
      this.tempGroup.name += ` (${copyNumber})`;
    }
  }

  private initRouteParams(): void {
    this.portalType = this.activatedRoute.snapshot.data.portalType;
  }

  private initFormTitles(): void {
    if (this.portalType === 'supply' && !this.rxDealMode) {
      this.modalSegmentTitle = SEGMENT_MODAL_TITLE_SSP;
      this.modalSegmentSubtitle = SEGMENT_MODAL_SUBTITLE_SSP;
    }
    if (this.rxDealMode) {
      this.modalSegmentTitle = SEGMENT_MODAL_TITLE_RX;
      this.modalSegmentSubtitle = SEGMENT_MODAL_SUBTITLE_RX;
      if (this.portalType === 'partner') {
        this.segmentTitleRx = SEGMENT_TITLE_BASIC_RX + '.';
      } else {
        this.segmentTitleRx = SEGMENT_TITLE_BASIC_RX + SEGMENT_TITLE_ADDITIONAL_RX;
      }
    }
  }
}
