import {
  AppTable,
  AppFilter,
  AppTableColumn,
  DropdownButtonsConfig,
  MainDropdownButton,
  DropdownButton
} from '../../../shared/_models/models';
import { CreateQueryParams } from '@libs/crud-request-nestjs';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {RxFormForecastSubmitActionType} from '../../exchange-forecasts/_services/exchange-forecasts.config';
dayjs.extend(utc);
dayjs.extend(timezone);

export const exchangeDealsTableConfig: AppTable = new AppTable('exchangeDealsTable', 1, 10, 'createdAt', 'DESC');

export const exchangeDealsFiltersConfig: AppFilter[] = [
  new AppFilter('exchangeDealsStatusSFilter', 'SELECT', 'status', 'Status', 'Select status',
    'multiple', true, true, null,
    [{id: 1, displayName: 'Active', value: 'ACTIVE', isDisabled: false},
      {id: 2, displayName: 'Inactive', value: 'INACTIVE', isDisabled: false}],
    [], null, null, null, null,
    [{id: 1, displayName: 'Active', value: 'ACTIVE', isDisabled: false}]),
  new AppFilter('exchangeDealsPhaseFilter', 'SELECT', 'phase', 'Phase', 'Select phase',
    'default', true, true, null,
    [{id: 1, displayName: 'Live', value: 'LIVE'},
      {id: 2, displayName: 'Scheduled', value: 'SCHEDULED'},
      {id: 3, displayName: 'Completed', value: 'COMPLETED'}],
    [{id: 1, displayName: 'Live', value: 'LIVE'}], null,
    null, null, null,
    [{id: 1, displayName: 'Live', value: 'LIVE'}]),
  new AppFilter('exchangeDealSearchFilter', 'SEARCH', 'searchExchangeDeal', 'Search Exchange Deal',
    'Search by deal id/name/description', 'default', true, true, null),
  new AppFilter('exchangeDealsPersonnelFilter', 'SELECT', 'String', 'Personnel', 'Filter by personnel',
    'default', true, false, null,
    [], []),
  new AppFilter('exchangeDealsAccountTypeFilter', 'SELECT', 'String', 'Account', 'Filter by account',
    'multiple', true, false, null, [], [],
    null, false, null, false, [{ id: 0, displayName: 'Nexxen', value: 'Nexxen'}],
    '', false, true),
  new AppFilter('exchangeDealTypeFilter', 'SELECT', 'String', 'Deal Type', 'Filter by type',
    'multiple', true, false, null, [], [],
    null, false, null, false, null, '', false, true),
  new AppFilter('exchangeDealsDSPFilter', 'SELECT_WITH_GROUPS', 'field', 'DSP', 'Filter by dsp',
    'multiple', true, false, null,
    [], [],
    null, null, null, false, undefined, undefined, undefined,
    true, undefined, undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined, undefined, []),
];

export const exchangeDealsPartnerFiltersConfig: AppFilter[] = [
  new AppFilter('exchangeDealsStatusSFilter', 'SELECT', 'status', 'Status', 'Select status',
    'multiple', true, true, null,
    [{id: 1, displayName: 'Active', value: 'ACTIVE', isDisabled: false},
      {id: 2, displayName: 'Inactive', value: 'INACTIVE', isDisabled: false}],
    [], null, null, null, null,
    []),
  new AppFilter('exchangeDealsPhaseFilter', 'SELECT', 'phase', 'Phase', 'Select phase',
    'default', true, true, null,
    [{id: 1, displayName: 'Live', value: 'LIVE'},
      {id: 2, displayName: 'Scheduled', value: 'SCHEDULED'},
      {id: 3, displayName: 'Completed', value: 'COMPLETED'}],
    [], null, null, null, null, []),
  new AppFilter('exchangeDealSearchFilter', 'SEARCH', 'searchExchangeDeal', 'Search Curated Marketplace Deal',
    'Search by deal id/name', 'default', true, true, null),
  new AppFilter('exchangeDealsDSPFilter', 'SELECT', 'field', 'DSP', 'Filter by dsp',
    'multiple', true, false, null, [], [])
];

export const exchangeDealsTableButtons = [
  {id: 'exchangeDealsCreateButton', type: 'PRIMARY-BUTTON', title: 'Create Exchange Deal', hasLoadingSpinner: false, auth: 'canCreate'}
];

export const exchangeDealsPartnerTableButtons = [
  // eslint-disable-next-line max-len
  {id: 'exchangeDealsCreateButton', type: 'PRIMARY-BUTTON', title: 'Create Deal', hasLoadingSpinner: false, auth: 'canCreate'}
];

export const exchangeDealsTableColumns: AppTableColumn[] = [
  {
    id: 'exchangeDealsStatus', prop: 'status', displayName: 'Status', type: 'tags-rx-dashboard', isSortEnabled: false,
    colWidth: '100px',
    tags: [
      {id: 'exchangeDealTagActive', name: 'Active', condition: 'active', tooltip: 'Active'},
      {id: 'exchangeDealTagInactive', name: 'Inactive', condition: 'inactive', tooltip: 'Inactive'},
    ]
  },
  {id: 'exchangeDealsExternalDealId', prop: 'externalDealId', displayName: 'Deal ID', type: 'text', isSortEnabled: true, maxWidth: '130px'},
  {
    id: 'exchangeDealNameDescription',
    prop: 'description',
    displayName: 'Description/Name',
    type: 'custom-function',
    isSortEnabled: true,
    valueFormatter: (val, row) => val ? val : row.name
  },
  {id: 'exchangeDealsAgency', prop: 'agency.name', displayName: 'Agency', type: 'text', isSortEnabled: true, colWidth: '150px'},
  {id: 'exchangeDealsBrand', prop: 'dealBrand.name', displayName: 'Brand', type: 'text', isSortEnabled: true, colWidth: '110px'},
  {id: 'exchangeDealsStartDate', prop: 'dealStart', displayName: 'Start Date', type: 'date', isSortEnabled: true},
  {id: 'exchangeDealsEndDate', prop: 'dealEnd', displayName: 'End Date', type: 'date', isSortEnabled: true},
  {
    id: 'exchangeDealsSspAccountDisplayName', prop: 'ssps', displayName: 'SSP', type: 'moreTag',
    isSortEnabled: false,
    moreTag: {displayNameProp: 'name', popoverTitle: 'More SSP'}
  },
  {
    id: 'exchangeDealsDspAccountDisplayName', prop: 'dspAccounts', displayName: 'DSP', type: 'moreTag',
    isSortEnabled: false,
    moreTag: {displayNameProp: 'nameDisplay', popoverTitle: 'More DSP'}
  },
  {
    id: 'dealsActions', prop: 'actions', displayName: 'Actions', type: 'actions',
    actions: [
      {id: 'exchangeDealEdit', name: 'edit', auth: 'canUpdate', displayName: 'Edit', hrefLink: '/edit', hrefIdProp: 'dealId'},
      // {id: 'exchangeDealDuplicate', name: 'duplicate', auth: 'canUpdate', displayName: 'Duplicate'},
    ]
  }
];

export const exchangeDealsPartnerTableColumns: AppTableColumn[] = [
  {
    id: 'exchangeDealsStatus', prop: 'status', displayName: 'Status', type: 'tags-rx-dashboard', isSortEnabled: false,
    colWidth: '100px',
    tags: [
      {id: 'exchangeDealTagActive', name: 'Active', condition: 'active', tooltip: 'Active'},
      {id: 'exchangeDealTagInactive', name: 'Inactive', condition: 'inactive', tooltip: 'Inactive'},
    ]
  },
  {id: 'exchangeDealsExternalDealId', prop: 'externalDealId', displayName: 'Deal ID', type: 'text', isSortEnabled: true, maxWidth: '130px'},
  {id: 'exchangeDealNameDescription', prop: 'name', displayName: 'Name', type: 'text', isSortEnabled: true},
  {id: 'exchangeDealsStartDate', prop: 'dealStart', displayName: 'Start Date', type: 'date', isSortEnabled: true},
  {id: 'exchangeDealsEndDate', prop: 'dealEnd', displayName: 'End Date', type: 'date', isSortEnabled: true},
  {
    id: 'exchangeDealsDspAccountDisplayName', prop: 'dspAccounts', displayName: 'DSP', type: 'moreTag',
    isSortEnabled: false,
    moreTag: {displayNameProp: 'nameDisplay', popoverTitle: 'More DSP'}
  },
  {
    id: 'dealsActions', prop: 'actions', displayName: 'Actions', type: 'actions',
    actions: [
      {id: 'exchangeDealEdit', name: 'edit', auth: 'canUpdate', displayName: 'Edit', hrefLink: '/edit', hrefIdProp: 'dealId'},
    ]
  }
];

export const initialExchangeDealsFilterQueryConfig: CreateQueryParams = {
  search: {
    status: {$in: ['ACTIVE']},
  },
  page: 1,
  sort: [ {
    field: 'dealEnd',
    order: 'ASC'
  }],
  limit: 10
};

export const initialExchangeSegmentsFilterQueryConfig: CreateQueryParams = {
  search:
    {
      status: {$eq: 'active'},
    }
  ,
  page: 1,
  sort: [{
    field: 'segmentAudience.name',
    order: 'ASC'
  }, {
    field: 'displayName',
    order: 'ASC'
  }
  ],
  limit: 25
};

export const rxSegmentAddIncludeButton: DropdownButtonsConfig = {
  main: {id: 'addInclusionGroup', label: 'Add Inclusion Group', buttonType: 'primary-button'},
  options: [
    {id: 'addInclusionGroupMedia', label: 'Add Media'},
  ],
};

export const rxSegmentAddExcludeButton: DropdownButtonsConfig = {
  main: {id: 'addExclusionGroup', label: 'Add Exclusion Group', buttonType: 'secondary-button'},
  options: [
    {id: 'addExclusionGroupMedia', label: 'Add Media'},
  ],
};

export type RxFormDealSubmitActionId =
  'createDeal' | 'updateDeal' | 'createForecast' |
  'updateForecast' | 'syncDeal' | 'runForecast' |
  'cloneDeal' | 'convertToDeal' | 'convertToForecast' |
  'cloneDealApproved' | 'convertToDealApproved';

export type RxFormDealSubmitActionType = 'create'|'update'|'clone'|'sync'|'convert';

export interface RxFormSubmitActionButtonConfig {type: 'main'|'option'; config: MainDropdownButton | DropdownButton}

export const rxFormDealSubmitButtonConfigs: Partial<Record<RxFormDealSubmitActionType, RxFormSubmitActionButtonConfig>> = {
  create: {
    type: 'main',
    config: {id: 'createDeal', label: 'Create Deal', buttonType: 'primary-button'}
  },
  update: {
    type: 'main',
    config: {id: 'updateDeal', label: 'Save Deal', buttonType: 'primary-button'}
  },
  sync: {
    type: 'option',
    config: {id: 'syncDeal', label: 'Save and Sync'},
  },
  clone: {
    type: 'option',
    config: {id: 'cloneDeal', label: 'Save and Clone'},
  },
  convert: {
    type: 'option',
    config: {id: 'convertToForecast', label: 'Save and Create Forecast'},
  }
};

export const getRxDealFormSubmitDropdownButtonConfig = (
  configs: Partial<Record<(RxFormDealSubmitActionType | RxFormForecastSubmitActionType), RxFormSubmitActionButtonConfig>>,
  actions: (RxFormDealSubmitActionType | RxFormForecastSubmitActionType)[]
): DropdownButtonsConfig => {
  const dropdownButtonConfig: DropdownButtonsConfig = { main: null, options: []};
  actions.forEach(action => {
    if (configs[action].type === 'main') {
      dropdownButtonConfig.main = configs[action].config as MainDropdownButton;
    } else if (configs[action].type === 'option') {
      dropdownButtonConfig.options.push(configs[action].config);
    }
  });
  return dropdownButtonConfig;
};

export const errorDictionary: {[key: string]: string} = {
  viewabilityFiltersError: 'Viewability filter must be a decimal number between 1 and 100',
  unrulyBundledDealError: 'You must select at least one Nexxen bundled deal.',
  sspDealListError: 'Please select at least one ssp deal.',
  videoAdSizeListError: 'Please select either include or exclude if you want to add ad sizes.',
  sspListError: 'Please select at least one ssp.',
  demandMarginError: 'Demand margin can\'t be empty if there are additional demand margins',
  zeroNotAllowedError: '\'0\' not allowed.',
};

export const exchangeDealsDataMock = {
  data: {
    items: [
      {
        'overallImpCap': null,
        'name': '',
        'auctionType': 'donotset',
        'dealFloors': [{
          'dealId': 20716,
          'dspAccountId': 5130,
          'minPrice': 20
        }],
        'dealExternalSyncs': [],
        'priorityId': 3,
        'externalDealId': '1R-RX1-04508-20220516400760',
        'dealId': 20716,

        'wseat': '',
        'demandMarginValue': 25,
        'dataFee': 0,
        'bidfloor': 20,
        'dealEnd': '2024-01-16T21:16:18.000Z',
        'dealStart': '2022-04-30T21:00:00.000Z',
        'description': 'TTD-Gale-VeteransUnited_United2020_CTV_WL-inclusion_Floor_$20',
        'status': 'active',
        'dspAccounts': [{
          'id': 5130,
          'name': 'The Trade Desk'
        }],
        'ssps': [{
          'id': 2057,
          'name': 'Unruly - 1R ZONE'
        }, {
          'id': 2064,
          'name': 'Unruly - HB'
        }, {
          'id': 2069,
          'name': 'Unruly - Google OB'
        }, {
          'id': 2118,
          'name': 'Unruly - OpenRTB'
        }, {
          'id': 2129,
          'name': 'Unruly - Outstream'
        }, {
          'id': 2153,
          'name': 'Unruly - OpenRTB (select ctv pubs)'
        }],
        'dealBrand': {
          'id': 1335,
          'name': 'Veterans United'
        },
        'agency': {
          'id': 877,
          'name': 'GALE Partners'
        },
        'impType': 'video',
        'mediaType': ['app'],
        'createdAt': '2022-05-16T09:46:59.000Z',
        'updatedAt': '2022-12-01T10:14:30.000Z',
        'deviceType': ['settopbox', 'connectedtv'],
        'geoTargeting': {
          'countries': ['US']
        }
      },
      {
        name: 'sagi_deal_2',
        dealStart: '2022-09-19T22:00:00.000Z',
        externalDealId: '1R-RX1-09187-20220712345678',
        dealId: 22045,
        agency: 'Yahoo',
        brand: 'Walmart',
        wseat: 'wseat1,wseat2',
        demandMarginValue: 25,
        dataFee: 2.6785,
        bidfloor: 3.3165,
        dealEnd: '2023-09-25T22:00:00.000Z',
        description: 'RX_Deal_Test-API_ver2',
        status: 'active',

        dspAccounts: [
          {
            id: 5069,
            name: 'Tremor',
          },
          {
            id: 5014,
            name: 'Mediamath',
          }
        ],
        ssps: [
          {
            id: 2093,
            name: 'IronSource',
          },
          {
            id: 2091,
            name: 'Taboola',
          }
        ],
        viewabilityPercentage: 33,
        impType: 'video',
        mediaType: [
          'site',
          'app'
        ],
        createdAt: '2022-07-24T22:34:32.788Z',
        updatedAt: null,
        deviceType: [
          'desktop',
          'mobile'
        ],
        geoTargeting: {
          countries: [
            'US'
          ],
          regions: [
            'al',
            'de'
          ],
          metros: [
            'new york',
            'detroit'
          ],
          zipCodes: [
            'A1A2A3',
            'B1B2B3'
          ]
        }
      }
    ],
    meta: {
      totalItems: 2,
      itemCount: 2,
      itemsPerPage: 10,
      totalPages: 1,
      currentPage: 1
    }
  }
};

export const exchangeDealsDataMock2 = {
  data: {
    items: [
      {
        name: 'sagi_deal',
        dealStart: '2022-07-18T22:00:00.000Z',
        externalDealId: '1R-RX1-00187-20220724103432',
        dealId: 22040,
        agency: 'Yahoo',
        brand: 'Walmart',
        wseat: 'wseat1,wseat2',
        demandMarginValue: 25,
        dataFee: 2.6785,
        bidfloor: 3.3165,
        dealEnd: '2023-09-25T22:00:00.000Z',
        description: 'RX_Deal_Test-API_ver2',
        status: 'inactive',

        dspAccounts: [
          {
            id: 5069,
            name: 'Tremor',
          },
          {
            id: 5014,
            name: 'Mediamath',
          }
        ],
        ssps: [
          {
            id: 2093,
            name: 'IronSource',
          },
          {
            id: 2091,
            name: 'Taboola',
          }
        ],
        viewabilityPercentage: 33,
        impType: 'video',
        mediaType: [
          'site',
          'app'
        ],
        createdAt: '2022-07-24T22:34:32.788Z',
        updatedAt: null,
        deviceType: [
          'desktop',
          'mobile'
        ],
        geoTargeting: {
          countries: [
            'US'
          ],
          regions: [
            'al',
            'de'
          ],
          metros: [
            'new york',
            'detroit'
          ],
          zipCodes: [
            'A1A2A3',
            'B1B2B3'
          ]
        }
      },
      {
        name: 'sagi_deal_2',
        dealStart: '2022-09-19T22:00:00.000Z',
        externalDealId: '1R-RX1-09187-20220712345678',
        dealId: 22045,
        agency: 'Yahoo',
        brand: 'Walmart',
        wseat: 'wseat1,wseat2',
        demandMarginValue: 25,
        dataFee: 2.6785,
        bidfloor: 3.3165,
        dealEnd: '2023-09-25T22:00:00.000Z',
        description: 'RX_Deal_Test-API_ver2',
        status: 'active',

        dspAccounts: [
          {
            id: 5069,
            name: 'Tremor',
          },
          {
            id: 5014,
            name: 'Mediamath',
          }
        ],
        sspAccounts: [
          {
            id: 2093,
            name: 'IronSource',
          },
          {
            id: 2091,
            name: 'Taboola',
          }
        ],
        viewabilityPercentage: 33,
        impType: 'video',
        mediaType: [
          'site',
          'app'
        ],
        createdAt: '2022-07-24T22:34:32.788Z',
        updatedAt: null,
        deviceType: [
          'desktop',
          'mobile'
        ],
        geoTargeting: {
          countries: [
            'US'
          ],
          regions: [
            'al',
            'de'
          ],
          metros: [
            'new york',
            'detroit'
          ],
          zipCodes: [
            'A1A2A3',
            'B1B2B3'
          ]
        }
      }
    ],
    meta: {
      totalItems: 2,
      itemCount: 2,
      itemsPerPage: 10,
      totalPages: 1,
      currentPage: 1
    }
  }
};





