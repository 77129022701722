<div class="app-filters-container" #filtersContainerEl>

  <ng-container *ngFor="let filter of filtersControls.controls; let i = index; trackBy: trackByFn">
    <ng-container *ngIf="!filters[i].isHidden && !isFilterCollapsible(filters[i].id)">
      <app-input
        [inputControl]="filter"
        [inputConfig]="filters[i]"
        [id]="filters[i].id + '_appInput'"
        [ngStyle]="{ 'grid-area': filters[i].gridArea }"
        (inputValueChanged)="onFilterChange($event, i)"
        (inputSelectSearched)="onFilterSearch($event, i)"
        (inputSortByChanged)="sortByChanged(i, $event)"
        (datePickerOpenChanged)="onDatePickerChange($event)"
      >
      </app-input>
    </ng-container>
  </ng-container>

  <div class="collapse-filters" *ngIf="hasCollapsibleFilters">
    <nz-collapse [nzBordered]="false">
      <nz-collapse-panel
        [nzHeader]="collapsePanelHeader"
        [(nzActive)]="isExpanded"
        [nzExpandedIcon]="expandedIconTpl">

        <ng-template #collapsePanelHeader>
          <span>More Filters</span>
          <span *ngIf="!isExpanded && activeCollapsibleFiltersCount > 0"
                class="active-filters-badge">
            {{activeCollapsibleFiltersCount}} selected
          </span>
        </ng-template>

        <ng-template #expandedIconTpl>
          <span class="small-font margin-right-small margin-left-small">
            <fa-icon icon="chevron-right" [rotate]="isExpanded ? 90 : 0"></fa-icon>
          </span>
        </ng-template>

        <ng-container *ngFor="let filter of filtersControls.controls; let i = index; trackBy: trackByFn">
          <ng-container *ngIf="!filters[i].isHidden && isFilterCollapsible(filters[i].id)">
            <app-input
              [inputControl]="filter"
              [inputConfig]="filters[i]"
              [id]="filters[i].id + '_appInput'"
              [ngStyle]="{ 'grid-area': filters[i].gridArea }"
              (inputValueChanged)="onFilterChange($event, i)"
              (inputSelectSearched)="onFilterSearch($event, i)"
              (inputSortByChanged)="sortByChanged(i, $event)"
              (datePickerOpenChanged)="onDatePickerChange($event)"
            >
            </app-input>
          </ng-container>
        </ng-container>

      </nz-collapse-panel>
    </nz-collapse>
  </div>

  <div class="filter-buttons whitespace-nowrap"
       [ngStyle]="{ 'gridColumn': filterButtonsColStart + ' / -1', 'align-items': (filterButtonsColStart === 1 ? 'flex-end' : '') }">
    <ng-container *ngFor="let button of buttons; let i = index">
      <ng-container *ngIf="!button.isHidden">
        <ng-container [ngSwitch]="button.type">

          <ng-container *ngSwitchCase="'LINK'">
            <div class="reset-filters">
              <a class="link-button" id="{{button.id}}"
                 (click)="$event.preventDefault(); onButtonClick(button.id)">{{button.title}}</a>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'BOLD'">
            <button nz-button class="font-icon-color-grey font-icon-hover-color-mid-dark" id="{{button.id}}"
                    (click)="$event.preventDefault(); onButtonClick(button.id)">{{button.title}}</button>
          </ng-container>

          <ng-container *ngSwitchCase="'PRIMARY-BUTTON'">
            <div class="table-buttons" [ngStyle]="{ 'padding-top': (filterButtonsColStart === 1 ? '18px' : '') }">
              <button *ngIf="authPermissions[button.auth]"
                      nz-button id="{{button.id}}" class="primary-button whitespace-nowrap" nzType="primary"
                      [nzLoading]="(button.hasLoadingSpinner && isDataLoading) || button.hasSpinner"
                      [disabled]="button.hasLoadingSpinner && isDataLoading || button.isDisabled"
                      [ngClass]="button.customClass || ''"
                      (click)="$event.preventDefault(); onButtonClick(button.id)">
                {{button.title}}
              </button>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'SECONDARY-BUTTON'">
            <div class="table-buttons" [ngStyle]="{ 'padding-top': (filterButtonsColStart === 1 ? '18px' : '') }">
              <button nz-button id="{{button.id}}" class="secondary-button whitespace-nowrap"
                      [nzLoading]="(button.hasLoadingSpinner && isDataLoading) || button.hasSpinner"
                      [disabled]="button.hasLoadingSpinner && isDataLoading || button.isDisabled"
                      [ngClass]="button.customClass || ''"
                      (click)="$event.preventDefault(); onButtonClick(button.id)">
                {{button.title}}
              </button>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'DELETE-ALL-BUTTON'">
            <div class="table-buttons" [ngStyle]="{ 'padding-top': (filterButtonsColStart === 1 ? '18px' : '') }">
              <button nz-button id="{{button.id}}" type="button"
                      [nzLoading]="(button.hasLoadingSpinner && isDataLoading) || button.hasSpinner"
                      [disabled]="button.hasLoadingSpinner && isDataLoading || button.isDisabled"
                      [ngClass]="button.customClass || ''"
                      nz-popconfirm [nzPopconfirmTitle]="deletePopconfirmTitleTpl"
                      (click)="$event.preventDefault()"
                      (nzOnConfirm)="onButtonClick(button.id)" nzOkText="Delete"
                      class="whitespace-nowrap secondary-button red-button small-font">
                {{button.title}}
              </button>
              <ng-template #deletePopconfirmTitleTpl>
                <fa-icon icon="exclamation-circle" class="font-icon-color-warning-mid-dark margin-right-small"></fa-icon>
                {{button.desc}}
              </ng-template>
            </div>
          </ng-container>

        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <ng-content></ng-content>

</div>


